<template>
  <div>
    <div class="title">我的班级</div>
    <div class="tab">
      <div :class="active == 1 ? 'active' : ''" @click="cut(1)">正在学习</div>
      <div :class="active == 2 ? 'active' : ''" @click="cut(2)">已完成学习</div>
    </div>
    <div class="content">
      <div class="item" v-for="item in list" :key="item.id">
        <div class="flex">
          <img :src="item.pic" alt="" />
          <div class="item_1">
            <div class="item_1_1">
              {{ item.title }}
            </div>
            <div class="item_1_2">
              {{ item.stu }}人已学 &nbsp;&nbsp;&nbsp;&nbsp; 学习时长{{
                item.ks
              }}课时
            </div>
          </div>
        </div>
        <div>
          <div class="item_2">学习进度{{ item.ksSpeed }}%</div>
          <div class="item_3" @click="gostudy(item)">签到学习</div>
        </div>
      </div>
      <div style="height: 500px"></div>
    </div>
    <!-- 弹窗 -->
    <div class="mask1" v-show="flag">
      <div>
        <img class="imgs" src="../../assets/icon.png" alt="" />
        <div class="popup">
          <div class="title1">学习身份核验</div>
          <div class="content" v-show="num == 1">
            在开始您的学习之前我们将对您进行学习身份核验届时会有核验短信发到您的手机请确认通过
          </div>
          <el-checkbox
            v-show="num == 1"
            style="margin-left: 45px"
            v-model="checked"
            >同意《职培通在线教育云平台学员核验规定》</el-checkbox
          >
          <div class="content1" v-show="num == 2">
            <input
              class="iut"
              v-model="userInfo.phone"
              disabled
              type="text"
              placeholder="手机号"
            />
            <div class="dis">
              <input
                v-model="verify"
                class="iut1"
                type="text"
                placeholder="验证码"
              />
              <div class="btn" v-if="time < 0" @click="getCode">获取验证码</div>
              <div class="btn" v-if="time >= 0">{{ time }} s</div>
            </div>
          </div>
          <div class="content2" v-show="num == 3">
            <img src="../../assets/wc.png" alt="" />
            <div>核验成功</div>
          </div>
          <div class="dian" @click="submit">
            {{
              num == 1
                ? "开始核验"
                : num == 2
                ? "提交核验"
                : num == 3
                ? "去学习"
                : ""
            }}
          </div>
        </div>
        <img class="img" @click="close" src="../../assets/close.png" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  data() {
    return {
      list: [],
      list1: [],
      list2: [],
      active: 1,
      page: 1,
      time: -1,
      verify: "",
      flag: false,
      num: 1,
      checked: false,
    };
  },
  computed: {
    ...mapState({
      userInfo: (state) => state.userInfo,
      learning: (state) => state.learning,
    }),
  },
  methods: {
    ...mapActions(["getMinePace", "getVerify", "getsmsVerificationr"]),
    //倒计时
    getTime() {
      var tiemr = setInterval(() => {
        if (this.time >= 0) {
          this.time--;
        } else {
          clearInterval(tiemr);
        }
      }, 1000);
    },
    //获取验证码
    getCode() {
      this.getVerify({
        kindof: 1,
        phone: this.userInfo.phone,
      }).then((res) => {
        if (res.code == 200) {
          this.time = 100;
          this.getTime();
        }
      });
    },
    gostudy(item) {
      this.$store.commit("SET_CLASSID", item.id);
      this.$store.commit("SET_DETAILS", item);

      if (this.learning == 1) {
        this.flag = true;
      } else {
        this.$router.push("/Layoutstudy/studyVideo");
      }
    },
    close() {
      this.flag = false;
      this.num = 1;
    },
    submit() {
      if (this.num == 1) {
        if (this.checked == false) {
          this.$message({
            message: "请勾选协议",
            type: "warning",
          });
        } else {
          ++this.num;
          return;
        }
      }
      if (this.num == 2) {
        if (this.verify == "") {
          this.$message({
            message: "请填写验证码",
            type: "error",
            offset: 80,
          });
          return;
        }
        this.getsmsVerificationr({
          id: this.userInfo.id,
          verify: this.verify,
          phone: this.userInfo.phone,
          username: this.userInfo.username,
          type: 1,
        }).then((res) => {
          if (res.code == 200) {
            ++this.num;
            this.$store.commit("SET_LEARNING", 2);
          } else {
            this.$message({
              message: res.msg,
              type: "error",
            });
          }
          return;
        });
      }
      if (this.num == 3) {
        this.$router.push("/Layoutstudy/studyVideo");
      }
    },
    cut(val) {
      this.active = val;
      if (val == 1) {
        this.list = this.list1;
      } else {
        this.list = this.list2;
      }
    },
    getlist() {
      ++this.page;
      this.getMinePace({
        username: this.userInfo.username,
        page: this.page,
      }).then((res) => {
        if (res.code == 200) {
          let list1 = [];
          let list2 = [];
          res.data.forEach((e, i) => {
            e.ksSpeed = +e.ksSpeed.replace("%", "");
            if (e.ksSpeed == 100) {
              list2.push(e);
            } else {
              list1.push(e);
            }
          });
          this.list1.push(...list1);
          this.list2.push(...list2);
          this.list.push(...list1);
          if (res.data.length == 15) {
            this.getlist();
          }
        }
      });
    },
  },
  mounted() {
    this.getMinePace({
      username: this.userInfo.username,
      page: this.page,
    }).then((res) => {
      if (res.code == 200) {
        let list1 = [];
        let list2 = [];
        res.data.forEach((e, i) => {
          e.ksSpeed = +e.ksSpeed.replace("%", "");
          if (e.ksSpeed == 100) {
            list2.push(e);
          } else {
            list1.push(e);
          }
        });
        this.list1 = list1;
        this.list2 = list2;
        this.list = list1;
        if (res.data.length == 15) {
          this.getlist();
        }
      }
    });
  },
};
</script>

<style lang="scss" scoped>
.title {
  font-size: 21px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #222222;
}
.mask1 {
  position: fixed;
  z-index: 99;

  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  .imgs {
    display: block;
    width: 250px;
    margin: 0 auto;
    vertical-align: middle;
  }
  .img {
    width: 40px;
    height: 40px;
    display: block;
    margin: 15px auto;
    cursor: pointer;
  }
  .popup {
    position: relative;
    z-index: 100;
    width: 414px;
    height: 377px;
    background: #ffffff;
    border-radius: 16px;
    .title1 {
      padding-top: 29px;
      text-align: center;
      font-size: 24px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #008bd5;
      line-height: 33px;
      span {
        font-size: 18px;
      }
      .span {
        font-weight: 500;
        font-size: 16px;
        color: #000;
      }
    }
    .content {
      font-size: 18px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #222222;
    }
    .content1 {
      margin: 20px 29px;
      .iut {
        outline: none;
        height: 40px;
        line-height: 30px;
        width: 360px;
        font-size: 18px;
        border: 0px none;
        border-bottom: 1px solid #cfcfcf;
      }
      .dis {
        margin-top: 23px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #cfcfcf;
        .btn {
          cursor: pointer;
          font-size: 18px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #222222;
        }
      }
      .iut1 {
        font-size: 18px;
        outline: none;
        height: 40px;
        line-height: 30px;
        border: 0px none;
      }
    }
    .content2 {
      img {
        display: block;
        width: 60px;
        height: 60px;
        margin: 29px auto 18px;
      }
      div {
        font-size: 18px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #222222;
        text-align: center;
      }
    }
    .dian {
      border-top: 2px solid #eaeaea;
      position: absolute;
      left: 0;
      bottom: 0;
      height: 50px;
      width: 100%;
      line-height: 50px;
      margin-top: 15px;
      text-align: center;

      font-size: 18px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #0067ff;
      cursor: pointer;
    }
  }
}
.tab {
  margin-top: 14px;
  height: 92px;
  line-height: 92px;
  background: #ffffff;
  display: flex;
  padding: 0 47px;
  box-sizing: border-box;
  div {
    cursor: pointer;
    position: relative;
    margin-right: 66px;
    font-size: 18px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #a7a7a7;
  }
  .active {
    font-size: 18px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #222222;
    &::before {
      position: absolute;
      display: block;
      bottom: 0;
      content: "";
      left: 50%;
      transform: translateX(-50%);
      width: 96px;
      height: 4px;
      background: #137cfb;
    }
  }
}
.content {
  margin-top: 14px;
  background-color: #fff;
  padding: 43px;
  box-sizing: border-box;
  .item {
    margin-bottom: 33px;
    border-bottom: 1px solid #e1e1e1;
    height: 115px;
    background: #ffffff;
    display: flex;
    justify-content: space-between;
    img {
      width: 140px;
      height: 81px;
    }
    .item_1 {
      margin-left: 24px;
      .item_1_1 {
        width: 306px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #7c7c7c;
      }
      .item_1_2 {
        margin-top: 12px;
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #7c7c7c;
      }
    }
    .item_2 {
      text-align: right;
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #7c7c7c;
    }
    .item_3 {
      cursor: pointer;
      margin-top: 26px;
      width: 100px;
      height: 30px;
      line-height: 30px;
      text-align: center;
      background: #e9f3ff;
      border: 2px solid #137cfb;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #137cfb;
    }
  }
}
</style>
